import React from 'react'
import { Menu, Dropdown, Avatar } from 'antd'
import { connect, useSelector } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import Icon from 'components/util-components/Icon'
import { signOut } from 'redux/actions/Auth'

const menuItem = [
  {
    title: 'Edit Profile',
    icon: EditOutlined,
    path: '/app/dashboards/profile',
  },
]

export const NavProfile = ({ signOut }) => {
  const { user } = useSelector((state) => state.auth)
  console.log("user details - ", user)

  const profileImg = '/img/profile.svg'
  // const profileImg = '/img/avatars/thumb-1.jpg'
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={user.displayImage || profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{user.firstName}</h4>
            <span className="text-muted">{user.userType}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            )
          })}
          <Menu.Item key={menuItem.length + 1} onClick={(e) => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <div className="nav-item" style={{ cursor: "pointer" }}>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Avatar src={user.displayImage || profileImg} />
          {/* <div className="pl-2 d-none d-sm-block profile-text">
            <div className="font-size-base font-weight-bold">{user?.firstName}</div>
          </div> */}
        </div>
      </div>
      {/* <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={user.displayImage} />
          <div className='pl-2 d-none d-sm-block profile-text'>
            <div className='font-size-base font-weight-bold'>{user?.firstName}</div>
          </div>
        </Menu.Item>
      </Menu> */}
    </Dropdown>
  )
}

export default connect(null, { signOut })(NavProfile)
