// let FirebaseConfig = {}

const FirebaseConfig = {
  apiKey: "AIzaSyB871UMAxkauQP66vgzytUEh16avUXTwuw",
  authDomain: "oumuamua-news.firebaseapp.com",
  projectId: "oumuamua-news",
  storageBucket: "oumuamua-news.appspot.com",
  messagingSenderId: "992530620430",
  appId: "1:992530620430:web:3972c5f0f8067340139c5f",
  measurementId: "G-8PC7785HD1"
};

export default FirebaseConfig
