const SITE_NAME = process.env.REACT_APP_SITE_NAME

const dev = {
  API_ENDPOINT_URL:
    'https://newsapi.24liveyard.com/api/v1'
}

const prod = {
  API_ENDPOINT_URL: 'https://newsapi.24liveyard.com/api/v1'
}

const test = {
  API_ENDPOINT_URL: 'https://newsapi.24liveyard.com/api/v1'
}

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      // return dev
      return process.env.REACT_APP_API_STAGE === 'Dev' ? dev : prod
    case 'production':
      return process.env.REACT_APP_API_STAGE === 'Dev' ? dev : prod
    case 'test':
      return test
    default:
      break
  }
}

export const env = getEnv()
