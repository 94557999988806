import {
  DashboardOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  BorderOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  HomeOutlined,
  StopOutlined,
  DotChartOutlined,
  NodeExpandOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  FundOutlined,
  VerifiedOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
  FundProjectionScreenOutlined,
  UsergroupAddOutlined,
  FormatPainterOutlined,
  NotificationOutlined,
  UserOutlined,
  ApartmentOutlined,
  SettingOutlined,
  BorderOuterOutlined,
  AlignCenterOutlined,
  CheckSquareOutlined,
  MedicineBoxOutlined,
  BuildOutlined,
  DeliveredProcedureOutlined,
  ExperimentOutlined,
  ShoppingFilled,
  ShopOutlined,
  FileImageOutlined,
  SafetyCertificateOutlined,
  MoneyCollectOutlined,
  UserSwitchOutlined,
  ExpandOutlined,
  PercentageOutlined,
  CalculatorOutlined,
  AuditOutlined,
  FileProtectOutlined,
  StarOutlined,
  TransactionOutlined,
  AppstoreAddOutlined,
  TagsOutlined,
  FormOutlined,
  ContactsOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { ShippingSVG } from "assets/svg/icon";

const extraNavTree = [
  {
    key: "extra",
    path: `${APP_PREFIX_PATH}/pages`,
    title: "sidenav.pages",
    icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "extra-pages",
        path: `${APP_PREFIX_PATH}/pages`,
        title: "sidenav.pages",
        icon: FileTextOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-pages-profile",
            path: `${APP_PREFIX_PATH}/pages/profile`,
            title: "sidenav.pages.profile",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "extra-pages-list",
            path: `${APP_PREFIX_PATH}/pages/user-list`,
            title: "sidenav.pages.userlist",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-pages-setting",
            path: `${APP_PREFIX_PATH}/pages/setting`,
            title: "sidenav.pages.setting",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "extra-auth",
        path: `${AUTH_PREFIX_PATH}`,
        title: "sidenav.authentication",
        icon: SafetyOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-auth-login-1",
            path: `${AUTH_PREFIX_PATH}/login-1`,
            title: "sidenav.authentication.login.1",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-login-2",
            path: `${AUTH_PREFIX_PATH}/login-2`,
            title: "sidenav.authentication.login.2",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-register-1",
            path: `${AUTH_PREFIX_PATH}/register-1`,
            title: "sidenav.authentication.register.1",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-register-2",
            path: `${AUTH_PREFIX_PATH}/register-2`,
            title: "sidenav.authentication.register.2",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-forgot-password",
            path: `${AUTH_PREFIX_PATH}/forgot-password`,
            title: "sidenav.authentication.forgetPassword",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "extra-errors",
        path: `${AUTH_PREFIX_PATH}/error-1`,
        title: "sidenav.errors",
        icon: StopOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-errors-error-1",
            path: `${AUTH_PREFIX_PATH}/error-1`,
            title: "sidenav.errors.error.1",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-errors-error-2",
            path: `${AUTH_PREFIX_PATH}/error-2`,
            title: "sidenav.errors.error.2",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      // {
      //   key: "dashboards-default",
      //   path: `${APP_PREFIX_PATH}/dashboards/default`,
      //   title: "Dashboard",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "dashboards-subadmins",
        path: `${APP_PREFIX_PATH}/dashboards/subadmins`,
        title: "Users & Roles",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-subadmins-type",
            path: `${APP_PREFIX_PATH}/dashboards/subadmins/subadminstype`,
            title: "Roles / Permissions",
            permissionKey: "admin_roles",
            icon: UserSwitchOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "dashboards-subadmins-list",
            path: `${APP_PREFIX_PATH}/dashboards/subadmins/subadmins-list`,
            title: "Users",
            permissionKey: "subadmins",
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "dashboards-user",
        path: `${APP_PREFIX_PATH}/dashboards/user`,
        title: "Subscribers",
        permissionKey: "users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },

      // {
      //   key: 'dashboards-analytic',
      //   path: `${APP_PREFIX_PATH}/dashboards/analytic`,
      //   title: 'sidenav.dashboard.analytic',
      //   icon: DotChartOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      {
        key: "dashboards-categories",
        path: `${APP_PREFIX_PATH}/dashboards/categories`,
        title: "Categories",
        permissionKey: "categories",
        icon: AppstoreAddOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "dashboards-articles",
        path: `${APP_PREFIX_PATH}/dashboards/articles`,
        title: "Articles",
        permissionKey: "articles",
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-article-suggestion",
        path: `${APP_PREFIX_PATH}/dashboards/article-suggestion`,
        title: "Article Suggestions",
        permissionKey: "article_suggestions",
        icon: FormOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-gallery",
        path: `${APP_PREFIX_PATH}/dashboards/gallery`,
        title: "Gallery",
        permissionKey: "gallery",
        icon: CameraOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-comments",
        path: `${APP_PREFIX_PATH}/dashboards/comments`,
        title: "Comments",
        permissionKey: "comments",
        icon: CommentOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-contact-requests",
        path: `${APP_PREFIX_PATH}/dashboards/contact-requests`,
        title: "Contact Requests",
        permissionKey: "contact_requests",
        icon: ContactsOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-widgets",
        path: `${APP_PREFIX_PATH}/dashboards/widgets`,
        title: "Widgets",
        permissionKey: "widgets",
        icon: LayoutOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "dashboards-tags",
        path: `${APP_PREFIX_PATH}/dashboards/tags`,
        title: "Tags",
        permissionKey: "tags",
        icon: TagsOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "dashboards-ratings",
        path: `${APP_PREFIX_PATH}/dashboards/ratings`,
        title: "Ratings",
        permissionKey: "ratings",
        icon: StarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-information policy",
        path: `${APP_PREFIX_PATH}/dashboards/information-policy`,
        title: "Information policy",
        permissionKey: "information_policy",
        icon: LayoutOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "dashboards-settings",
        path: `${APP_PREFIX_PATH}/dashboards/settings`,
        title: "Settings",
        permissionKey: "settings",
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: [],
      },


      // {
      //   key: "dashboards-users",
      //   path: `${APP_PREFIX_PATH}/dashboards/users`,
      //   title: "Users",
      //   icon: UsergroupAddOutlined,
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: "dashboards-users-usergroup",
      //       path: `${APP_PREFIX_PATH}/dashboards/users/usergroup/usergroup-list`,
      //       title: "User Group",
      //       permissionKey: "users-userGroup",
      //       icon: UsergroupAddOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "dashboards-users-customers",
      //       path: `${APP_PREFIX_PATH}/dashboards/users/customer/customer-list`,
      //       title: "Customer",
      //       permissionKey: "users-customer",
      //       icon: UserOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },

      //     {
      //       key: "dashboards-users-vendors",
      //       path: `${APP_PREFIX_PATH}/dashboards/users/vendor/vendor-list`,
      //       title: "Vendor",
      //       permissionKey: "users-vendor",
      //       icon: UserOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },


    ],
  },
];

// {
//   key: 'dashboards-catalog-medicinetype',
//   path: `${APP_PREFIX_PATH}/dashboards/catalog/medicinetype/medicinetype-list`,
//   title: 'Medicine Type',
//   icon: FormatPainterOutlined,
//   breadcrumb: false,
//   submenu: [],
// },
// {
//   key: 'dashboards-catalog-manufacturer',
//   path: `${APP_PREFIX_PATH}/dashboards/catalog/manufacturer/manufacturer-list`,
//   title: 'Manufacturer',
//   icon: FormatPainterOutlined,
//   breadcrumb: false,
//   submenu: [],
// },
// {
//   key: 'dashboards-catalog-composition',
//   path: `${APP_PREFIX_PATH}/dashboards/catalog/composition/composition-list`,
//   title: 'Composition',
//   icon: FormatPainterOutlined,
//   breadcrumb: false,
//   submenu: [],
// },






const navigationConfig = [
  ...dashBoardNavTree,
  // ...appsNavTree,
  // ...componentsNavTree,
  // ...extraNavTree,
  // ...docsNavTree,
];

export default navigationConfig;
